<template>
    <div>
    <h1>
        Ön Bilgilendirme Formu
    </h1>
    <h3>
        ÖNEMLİ:
    </h3>
    <p>
        Bu hizmeti ilk defa kullanmadan önce Mesafeli Satış Sözleşmesinin tamamını dikkatle okuyunuz.Son KULLANICI, Sözleşme
        konusu dijital ürünün ilk kullanımından veya çalıştırmasından itibaren işbu Sözleşmede geçen anlaşma koşullarıyla
        bağlı olduğunu, bu koşulları başka bir ihbara gerek kalmadan okumuş ve kabul etmiş sayılır. İşbu hükümleri kabul
        etmemesi halinde SON KULLANICI, sözleşme konusu ürün ve hizmetten yararlanamaz ve kullanamaz. Bu koşullar dışındaki
        kullanım halleri SATICI telif haklarının ihlali olarak nitelendirilecektir.
    </p>
    <p>
        Sözleşme konusu hizmetle ilgili tüm fikri ve sınai haklar GENOVA EĞİTİM VE BİLİŞİM HİZMETLERİ LTD ŞTİ e aittir.
        www.dikotr.com da yer alan herhangi bir dijital içeriğin izinsiz olarak kullanılması, çoğaltılması, yayılması,
        satışa sunulması ve benzeri durumlarda ilgili kişi veya kişiler hakkında hukuki ve cezai yaptırımlara
        başvurulacaktır.
    </p>
    <h2>
        1. TARAFLAR
    </h2>
    <p>
        İşbu Mesafeli Satış Sözleşmesi (“Sözleşme”); ("Alıcı") ile Ömerağa Mah. Akçacami Cad. Turhan Apartmanı No:20 İç kapı
        no:5 İzmit/Kocaeli adresinde bulunan GENOVA EĞİTİM VE BİLİŞİM HİZMETLERİ LTD ŞTİ ("Satıcı") arasında aşağıda
        belirtilen hüküm ve şartlar çerçevesinde elektronik ortamda kurulmuştur.
    </p>
    <h2>
        SATICI BİLGİLERİ
    </h2>
    <p>
        GENOVA EĞİTİM VE BİLİŞİM HİZMETLERİ LTD ŞTİ
    </p>
    <p>
        Adres: Ömerağa Mah. Akçacami Cad. Turhan Apartmanı No:20 İç kapı no:5 İzmit/Kocaeli
    </p>
    <p>
        Telefon: 0 262 331 00 20
    </p>
    <p>
        E-Posta: info@dikotr.com
    </p>
    <h2>
        ALICI BİLGİLERİ
    </h2>
    <p>
        Adı Soyadı: {{informations.name}}
        <br>
        Adres: {{informations.address}}
        <br>
        Telefon: {{informations.phone}}
        <br>
        E-Posta: {{informations.email}}
        <br>
        VKN / TCKN: {{informations.identificationNumber}}
        <br>
        Vergi Dairesi: {{informations.taxDepartment}}
        <br>

    </p>
    <h2>
        Madde 2: Konu
    </h2>
    <p>
        İşbu Sözleşme'nin konusu; ALICI' nın, SATICI’ ya www.dikotr.com web sitesinden elektronik ortamda satın aldığı, işbu
        Ön Bilgilendirme Formu ve mesafeli satış sözleşmesinde bahsi geçen nitelikleri taşıyan ürünün/ hizmetin satışı ile
        ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümleri
        gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.

        ALICI “www.dikotr.com” internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile
        teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda satın almaya ilişkin gerekli
        teyidi verdiğini kabul, beyan ve taahhüt eder. ALICI bu Ön Bilgilendirmeyi elektronik ortamda teyit etmekle,
        mesafeli sözleşmelerin akdinden önce, SATICI tarafından ALICI’ya verilmesi gereken adres, siparişi verilen ürünlere
        ait temel özellikler, ürünlerin vergiler dâhil fiyatı, ödeme ve teslimat bilgilerini de doğru ve eksiksiz olarak
        edindiğini teyit etmiş olur.
    </p>
    <h2>
        MADDE 3: SATIŞA KONU ÜRÜN/ÜRÜNLER, ÖDEME BİLGİLERİ ve TESLİMAT
    </h2>
    <p>
        ALICI, işbu Ön Bilgilendirme Formu’ nda ve Mesafeli Satış Sözleşmesi’nde (“Sözleşme”)  özellikleri detaylı olarak belirtilen özgün ve kayıtlı dijital içerik (“ÜRÜN”), dikkat ve konsantrasyon terapi hizmetlerini içermektedir.

Dikkat ve konsantrasyon terapi hizmetleri; dikkat, konsantrasyon, mantık, hafıza ve muhakeme egzersizlerini içermektedir. Egzersizlerin her on ikisi bir set olarak kabul edilmekte olup 48 adet set mevcuttur. Bu setler 4-17 yaş aralığındaki bireylere uygulanacaktır. Kullanıcının talebine göre günlük, haftalık ve aylık olarak kullanılabilmektedir. Setler uygulandıktan sonra çalışmanın sonunda istatistik verileri sistem tarafından hazırlanacaktır.

Hizmetler paketler halinde sunulacak olup hizmetlerin karşılığı ödenecek ücret web sitesinde yer alacaktır. Alıcı site içerisinde yer alan ücret politikasını peşinen kabul,beyan ve taahhüt eder.

Satın alınan Ürün’ün diğer özellikleri ve kullanım koşulları web sitesinde yer alan bilgilerde ve işbu Ön Bilgilendirme Formu’ nda ve üyelik sözleşmesinde detaylı olarak belirtilmiştir.
    </p>
    <h2>
        3.2.ÜRÜNÜN ÖDEME YÖNTEMLERİ
    </h2>
    <p>
        Paket içerisinde belirtilen süre için ürün bedeli peşin olarak (“Ürün, Bedeli), tüm vergileri kapsar. Ödeme, aşağıda belirtilen yöntemlerden biri ile yapılabilecektir:
    </p>
    <ul>
        <li>
            İyzico ile Ödeme                   : Ödeme, SATICI’nın www.dikotr.com web sayfasında satın alınmak istenen ürün/hizmetin “iyzico ile Ödeme İçin” ödeme yönteminin seçilerek satın almanın devam ettirilmesidir. ALICI, Onayla ve Devam Et butonunun tıklanmasının ardından iyzico Güvenli Ödeme sayfasına yönlendirilerek ödemesini gerçekleştirir.
        </li>
        <li>
            Havale/Eft Yolu ile Ödeme : Ödeme, SATICI’nın www.dikotr.com web sayfasında satın alınmak istenen ürün/hizmet “Havale/EFT ile Ödemek İçin” ödeme yönteminin seçilerek satın almanın devam ettirilmesidir. Ödeme işlemini tamamlamak için belirttiğiniz e-mail adresinize gönderilen banka hesap numaralarımızdan birine 5 gün içerisinde ödeme yapmanız gerekmektedir. Havale/EFT yolu ile ödeme sırasında ödeme açıklamasına, “Havale/EFT ile Ödemek İçin” butonunu tıkladığınızda size özel oluşturulan referans numarasını yazmanız gereklidir. Bu ödeme yönteminin seçilmesi halinde, ödeme sağlayıcı kuruluşlar tarafından ilgili ödeme için havale veya EFT masrafları ALICI’ ya yansıtılabilir. Böyle bir durumda, SATICI’ nın ilgili havale veya EFT masraflarından sorumluluğu yoktur.
        </li>
        <li>
            Kredi Kartı ile Ödeme         : www.dikotr.com web sayfasında satın alınmak istenen ürün/ hizmet ödeme bilgileri aşamasında yer alan yönergelerin izlenerek kredi kartı kullanmak sureti ile belirli bankalarla anlaşmalı olarak, taksit seçenekleriyle yapılan ödeme yöntemidir. Bu ödeme yönetiminin seçilmesi halinde, Ürün/ hizmet Bedeli, yetkili elektronik ödeme sağlayıcı kuruluş tarafından tahsil edilmektedir. Taksitle ödeme yönteminin seçilmesi halinde, ödeme peşin fiyat üzerinden gerçekleşir, ödeme sağlayıcı kuruluştan kaynaklı vade farkı ALICI’ ya yansıtılmaz.
        </li>
    </ul>
    <p>
        3.2.1.Ödeme sırasında ALICI tarafından yapılabilecek hatalar nedeni ile ödemede çıkabilecek aksaklıklardan SATICI sorumlu değildir.
    </p>
    <p>
        3.2.2.İnternet üzerinden kredi kartı ile yapılan ödemelerin bedelleri üyenin kredi kartından otomatik olarak çekilir. Ödeme işlemi onaylandıktan sonra hizmet kullanıma açılır. ALICI Havale/Eft ile ödemeyi gerçekleştirdiğinde, ücretin hesaba geçmesinin ardından 24 saat içerisinde ürün/ hizmet kullanıcının erişimine online açılır. www.dikotr.com sitesi indirim veya artırım ücret uygulamasını döneme göre değiştirme hakkına sahiptir.
    </p>
    <h2>
        3.3.TESLİMAT : Elektronik ortam
    </h2>
    <h2>MADDE 4:  GENEL HÜKÜMLER</h2>
    <p>
        4.1. ALICI ve SATICI arasında; ALICI tarafından Ön Bilgilendirme Formu’ nun okunduğunun ve teyit edildiğinin; Mesafeli Satış Sözleşmesi’ nin okunduğunun ve kabul edildiğinin beyan edildiği an internet üzerinden akdedilmiş olup, bu andan itibaren hüküm ve sonuçlarını doğurur. Sözleşme'nin bir nüshası ALICI’ nın belirttiği e-mail adresine gönderilir.
    </p>
    <p>
        4.2.ALICI, satın aldığı Ürün ile ilgili; Ön Bilgilendirme Formu’ nu, Mesafeli Satış Sözleşmesi’ni ve Üyelik Sözleşmesini okuyarak onaylamasını; ÜRÜN bedeline ilişkin ödemeyi gerçekleştirmesini müteakip başka bir işleme gerek kalmaksızın satış tamamlanır. Anılan onay işlemleri, ÜRÜN’ ün kullanım kapsam ve şartlarını kabul anlamına gelir. Bu suretle ÜRÜN, belirli süre boyunca ve koşullarla KULLANICI’ nın erişimine, online olarak açılır.
    </p>
    <p>
        4.3.ALICI, KULLANICI’ ya ait bilgileri tam ve eksiksiz şekilde girmekle yükümlü olup, ALICI’ nın eksik veya hatalı şekilde bilgi vermesi halinde Ürün’ün kullanıma açılamaması durumunda SATICI’ nın sorumluluğu bulunmamaktadır. ALICI, işbu Sözleşme’nin kurulması esnasında SATICI tarafından KULLANICI’ ya ait kişisel verilerin işlenmesine ilişkin gerekli bilgilerin temin edildiğini, bu kapsamda SATICI ile paylaştığı KULLANICI’ ya ait kişisel verileri paylaşmaya yetkili olduğunu, KULLANICI’ ya yönelik KVKK kapsamında gerekli aydınlatma yükümlülüğünü yerine getirdiğini (gereği halinde açık rızasını aldığını), bu sebeple her türlü sorumluluğun kendisinde olduğunu kabul eder.
    </p>
    <p>
        4.4.Satın alınan ÜRÜN’ ün özelliğine göre, Ön Bilgilendirme Formunda ayrıntıları, süresi ve şekli belirlendiği üzere, taahhüt edilen edimler SATICI tarafından işbu Ön Bilgilendirme Formuna, mesafeli satış sözleşmesine ve üyelik sözleşmesine uygun olarak ifa edilir.
    </p>
    <p>
        4.5.Satın alınan paket programı, satış ve kullanımı 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile ilgili mevzuat hükümlerine tabidir.
    </p>
    <p>
        4.6. Satın alınan her bir paket programı Sözleşmede belirtilen sürede, her halde 30 günlük yasal süreyi aşmamak kaydıyla ile KULLANICI’ nın kullanımına açılır. Bu süre içinde program kullanıma açılmaz ise, KULLANICI sözleşmeyi sona erdirebilir. Kullanıma açılma satın alma sonrasında satıcı tarafından sağlanacaktır. Üyenin almış olduğu Diko (Dikkat ve Konsantrasyon Terapisi) uygulama haklarını yine üyenin yönetim paneline yüklenecektir.
    </p>
    <p>
        4.7.ALICI, satın aldığı terapi programının bedelini ödemez veya banka kayıtlarında iptal ederse, SATICI’ nın terapi programını içeren dijital içeriği Alıcının kullanımına açma yükümlülüğü sona erer.
    </p>
    <p>
        4.8.Terapi programını içeren dijital içerik KULLANICI kullanımına açıldıktan sonra, ALICI’ nın ödeme yaptığı kredi kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı tespit edilirse ve satılan program bedeli ilgili banka veya finans kuruluşu tarafından SATICI' ya ödenmez ise, SATICI sözleşmeyi sona erdirir.
    </p>
    <p>
        4.9.SATICI’ nın öngöremeyeceği mücbir sebepler oluşursa ve terapi programı süresinde kullanıma açılmaz ise, durum ALICI’ya bildirilir. ALICI, siparişin iptalini veya engel ortadan kalkana dek dijital içeriğin kullanıma açılmasının ertelenmesini talep edebilir. ALICI siparişi iptal ederse; ödemeyi havale/eft ile yapmış ise iptalinden itibaren iade ödemesi 10 gün içerisinde gerçekleştirilir. ALICI, ödemeyi kredi kartı ile ya da iyzico ödeme yöntemi ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 10 gün içinde ürün bedeli bankaya iade edilir. Ancak ALICI, ilgili bankanın para iadesini hesabına 2-3 haftalık bir sürede yapabileceğini kabul eder ve SATICI’ nın bundan doğan bir sorumluluğu olmadığını bilir.
    </p>
    <h2>
        MADDE 5: CAYMA HAKKI
    </h2>
    <p>
        <strong>
            İşbu Sözleşme konusu ÜRÜN, online ve kayıtlı hizmetlerini içeren dijital içeriktir. Bu bakımdan, 6502 sayılı Tüketicinin Korunması Hakkında Kanun’ a dayanarak hazırlanan Mesafeli Sözleşmeler Yönetmeliği’nin 15/ğ Maddesi uyarınca “elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallara ilişkin sözleşmeler” kapsamında kalması sebebiyle ALICI’ nın cayma hakkı bulunmamaktadır.
        </strong>
    </p>
    <h2>
        MADDE 6: YETKİLİ MAHKEME
    </h2>
    <p>
        Taraflar, Sözleşme'nin uygulanmasından ve yorumundan doğan ihtilaflarda mevzuat çerçevesinde belirlenen parasal sınırlar dahilinde ALICI ve SATICI’nın ikametgâhının bulunduğu yerdeki Tüketici Hakem Heyetleri aşan durumlarda; Kocaeli Mahkemeleri ve icra daireleri yetkilidir.
    </p>
    <h2>
        MADDE 7: GEÇERLİLİK
    </h2>
    <p>
        İşbu ön bilgilendirme formu, elektronik ortamda tüketici tarafından okunarak kabul edildikten sonra Mesafeli Satış Sözleşmesi kurulması aşamasına geçilecektir.
    </p>
</div>
</template>
<script>
export default {
    name: "PreInformText",
    data() {
        return {
            //
        }
    },
    props: {
        informations: {
            type: Object,
            required: true
        }
    },
}
</script>