<template>
  <section v-if="Object.keys(productPackages).length" id="pricing-plan">
    <!-- title text and switch button -->
    <div class="checkout-address" v-if="planSelected">
      <CheckoutAddress :total="priceNcount" />
    </div>
    <!--/ title text and switch button -->
    <!-- pricing plan cards -->
    <div class="subject-count" v-if="!planSelected">
      <div class="text-center">
        <h1 class="">
          {{ $t("Pricing Plans") }}
        </h1>
        <p class="mb-2 pb-75">
          {{ $t(
                "Buy Diko as much as you want, use it whenever you want. Get as much discount as you buy. Use with no extra fees, subscriptions or annual fees."
              )
            }}
        </p>
      </div>
      <b-row class="pricing-card mb-3">
        <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
          <b-row>
            <b-col md="4">
              <div class="d-flex justify-content-start align-items-center">
                <b-card-title class="m-0">
                  {{ $t("Your Program Rights") }}
                  <b-badge variant="primary" pill>{{ programRights }}</b-badge>
                </b-card-title>
                <b-card-title class="m-0 ml-1">
                  {{ $t("Your Refund Rights") }}
                  <b-badge variant="primary" pill>{{ refundRights }}</b-badge>
                </b-card-title>
              </div>
            </b-col>
            <b-col md="8">
              <div class="d-flex justify-content-end align-items-center">
                <b-card-title class="m-0">
                  {{ $t("Enter the Number of Clients You Want to Buy:") }}
                </b-card-title>
                <b-form-group class="m-0 ml-1">
                  <b-form-input v-model="ldot" class="w-100 h-full price-box"
                    style="font-weight: bolder; color: #666666 !important" />
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <!-- slider -->
          <vue-slider v-model="value" :direction="direction" height="20px" dot-size="25" />
        </b-col>
      </b-row>
      <b-row class="pricing-card">
        <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
          <b-row>
            <b-col md="2" v-for="(item, index) in productPackages" :key="item._id">
              <b-card class="price-card" align="center" :class="{ popular: checkSelected(index) }"
                @click="packageClick(index)">
                <div v-show="checkSelected(index)" class="pricing-badge text-left">
                  <b-badge variant="primary" pill>
                    {{ $t("Chosen") }}
                  </b-badge>
                </div>
                <h3>{{ item.title }}</h3>
                <b-card-text>
                  {{ item.subtitle }}
                </b-card-text>
                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <span class="pricing-basic-value font-weight-bolder">{{ item.pricePer.toLocaleString("tr-TR")
                      }}</span>
                    <sup class="font-medium-1 font-weight-bold">₺</sup>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold"><!-- {{ $t("/month") }} -->/
                      {{ $t("Subject") }}</sub>
                  </div>
                  <small class="annual-pricing text-muted">{{ (item.pricePer * item.amount).toLocaleString("tr-TR") }}TL
                    {{ $t("Total") }}</small>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col md="6" class="info-box">
              <b-card class="">
                <ul class="h-100 d-flex flex-column justify-content-between">
                  <li v-for="item in productPackages[0].planBenefits" class="pricing-basic-value font-weight-bolder">
                    {{ item }}
                  </li>
                </ul>
              </b-card>
            </b-col>
            <!-- <b-card>
                <b-row class="w-100 d-flex justify-content-between match-height">
                </b-row>
              </b-card> -->
            <b-col md="6" class="d-flex flex-column">
              <b-card class="price-box font-white">
                <div class="d-flex justify-content-end m-2">
                  <div class="d-flex flex-column justify-content-between w-100 m-1">
                    <div class="table-row">
                      <div class="price-per-sub">{{ $t("Per Client") }}</div>
                      <div class="price-per-p">
                        {{ productPackages
                              .filter((x) => x.amount <= this.ldot)
                              .splice(-1)[0].pricePer
                          }}₺
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="discount">{{ $t("Discount") }}</div>
                      <div class="discount-p">
                        {{ (productPackages.filter((x) => x.amount <= this.ldot)[0]
                              .pricePer -
                              productPackages
                                .filter((x) => x.amount <= this.ldot)
                                .splice(-1)[0].pricePer) *
                            ldot
                          }}₺ ({{ productPackages
                              .filter((x) => x.amount <= this.ldot)
                              .splice(-1)[0].discount
                          }}%)
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="total">{{ $t("Total") }}</div>
                      <div class="total-p">{{ totalPrice }} ₺ + {{ $t("Taxes") }}</div>
                    </div>
                  </div>
                </div>
                <b-button variant="primary" class="m-2 anim-button" @click="proceedToPayment">
                  {{ $t("Continue Payment") }}
                  <feather-icon icon="ArrowRightIcon" class="ml-50"></feather-icon>
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="pricing-free-trial">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left">
              <h3 class="text-primary">
                {{ $t(
                      "Still not convinced? Start with a FREE CLIENT and than you can buy more..."
                    )
                  }}
                {{ $t(
                      "You will get full access to with all the features for your first client."
                    )
                  }}
              </h3>
              <!-- <h3 class="text-primary">
                
              </h3> -->
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- <div class="pricing-faq">
      <h3 class="text-center">
        {{ $t("FAQ's") }}
      </h3>
      <p class="text-center">
        {{ $t("Let us help answer the most common questions.") }}
      </p>
      <b-row class="py-2"> </b-row>
    </div> -->
    <!--/ pricing faq -->
  </section>
  <section v-else>
    <b-card>
      <b-card-title>
        {{ $t("You cannot make purchases until your account is approved.") }}
      </b-card-title>
    </b-card>
  </section>
</template>

<script>
import CheckoutAddress from "@/views/pages/pricing/CheckoutAddress.vue";
import VueSlider from "vue-slider-component";
import store from "@/store/index";
import {
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BCardTitle,
  BFormTextarea,
  BTable,
  BContainer,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
const cities = require("./turkey.js");
// const priceData = require("./pricing-data.js");
import axios from "@axios";
import { ref } from "@vue/composition-api";

const {
  axiosRoutes: { productPackage: axiosroute },
} = require("/G_CONFIG");
// const pricingdb = require("./pricingdb.js");
// import axios from "@axios";
/* eslint-disable global-require */
export default {
  components: {
    BContainer,
    BTable,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    VueSlider,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormSelectOption,
    BCardTitle,
    BFormTextarea,
    CheckoutAddress,
  },
  directives: {
    Ripple,
  },
  setup() {
    const productPackages = ref([]);
    axios.get(axiosroute.getAll).then(({ data }) => {
      productPackages.value = data;
    });
    return { productPackages };
  },
  data() {
    return {
      priceNcount: {
        totalPrice: 0,
        amount: 0,
        selectedPlan: {},
      },
      refundRights: 10,
      programRights: 10,
      totalPrice: 1000,
      status: "monthly",
      monthlyPlanShow: true,
      // pricing: priceData,
      planSelected: false,
      cities: cities,
      selectedCity: "",
      district: [],
      selectedDistrict: "",
      ldot: 1,
      dir: "ltr",
      fullName: "",
      email: "",
      address: "",
      paymentMethod: "",
    };
  },
  created() {
    this.cities = cities;
    axios.get("/user/refunds").then(({ data }) => {
      this.refundRights = data.refunds;
      this.programRights = data.programs;
    });
    axios.get(axiosroute.getAll).then(({ data }) => {
      this.totalPrice = data[0].pricePer;
    });
  },
  methods: {
    packageClick(args) {
      this.ldot = this.productPackages[args].amount;
    },
    checkSelected(index) {
      if (
        index == this.productPackages.length - 1 &&
        this.ldot > 0 &&
        this.productPackages[index].amount <= this.ldot
      ) {
        return true;
      }
      if (
        this.ldot > 0 &&
        this.productPackages[index].amount <= this.ldot &&
        this.productPackages[index + 1].amount > this.ldot
      ) {
        return true;
      }
    },
    proceedToPayment() {
      const plans = this.productPackages.filter((x) => x.amount <= this.ldot);
      const currentPlan = plans[plans.length - 1];
      const nextPlan = this.productPackages.filter((x) => x.amount > this.ldot)[0];
      console.log(plans);
      if (this.ldot == 0) {
        this.$swal({
          title: this.$t("Warning"),
          text: this.$t(
            "Please select a plan or subject count. Subject count can't be 0."
          ),
          icon: "warning",
        });
        return;
      }
      if (nextPlan && this.totalPrice >= nextPlan.amount * nextPlan.pricePer) {
        this.$swal({
          title: this.$t("Warning"),
          text: this.$t(
            "If you purchase this plan, you will pay more per subject than the next plan. Want to buy the next plan?"
          ),
          html: `
      <div>${this.$t(
        "If you purchase this plan, you will pay more per subject than the next plan. Want to buy the next plan?"
      )}</div>
        <div class="ali d-flex flex-column justify-content-between w-100 m-1">
          <div class="table-row">
            <div class="text-class">&nbsp;</div>
            <div class="text-class">${this.$t("Current Plan")}</div>
            <div class="text-class">${this.$t("Next Plan")}</div>
          </div>
          <div class="table-row">
            <div class="text-class">${this.$t("Subjects")}</div>
            <div class="text-class">${this.ldot}</div>
            <div class="price-class">${nextPlan.amount}</div>
          </div>
          <div class="table-row">
            <div class="text-class">${this.$t("Total")}</div>
            <div class="text-class">${this.totalPrice.toLocaleString("tr-TR")} TL</div>
            <div class="price-class">${(
              nextPlan.amount * nextPlan.pricePer
            ).toLocaleString("tr-TR")} TL</div>
          </div>
          <div class="table-row">
            <div class="text-class">${this.$t("Per Subject")}</div>
            <div class="text-class">${(this.totalPrice / this.ldot).toLocaleString(
              "tr-TR"
            )} TL</div>
            <div class="price-class">${nextPlan.pricePer.toLocaleString("tr-TR")} TL</div>
          </div>
        </div>
      `,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Reject"),
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.priceNcount.totalPrice = nextPlan.amount * nextPlan.pricePer;
            this.priceNcount.amount = nextPlan.amount;
            this.priceNcount.selectedPlan = nextPlan;
            this.planSelected = true;
          } else {
            this.priceNcount.totalPrice = this.totalPrice;
            this.priceNcount.amount = this.ldot;
            this.priceNcount.selectedPlan = currentPlan;
            this.planSelected = true;
          }
        });
      } else {
        this.priceNcount.totalPrice = this.totalPrice;
        this.priceNcount.amount = this.ldot;
        this.priceNcount.selectedPlan = currentPlan;
        this.planSelected = true;
      }
    },
    tooglePlan() {
      if (this.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
  },
  computed: {
    total() {
      let total = 0;
      total = (this.ldot * 1000) / 1.1;
      return total;
    },
    value: {
      get() {
        return this.ldot;
      },
      set(ldot) {
        this.ldot = ldot;
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
  watch: {
    ldot(newValue) {
      let plans = this.productPackages.filter((plan) => {
        if (plan.amount <= newValue) {
          return plan;
        }
      });
      if (plans.length > 0) {
        this.totalPrice = plans[plans.length - 1].pricePer * newValue;
      } else {
        this.totalPrice = 0;
      }
    },
  },
};

/* eslint-disable global-require */
</script>

<style lang="scss">
.anim-button {
  animation: mymove 2s infinite;
}
/* infinite animation for scaling little bit up and down*/
@keyframes mymove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.price-box {
  /* background-color: #32bde6 !important; */
  //color: #fff !important;
}
.table-row {
  display: flex;
  justify-content: end;
  align-items: center;
  /* padding: 0.5rem 0; */
  /* border-bottom: 1px solid #e9ecef; */
}
.price-per-sub,
.sub-total,
.discount,
.total,
.tax {
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  border-bottom: #484848 1px solid;
  padding: 5px;
  /* background-color: #17a2b8; */
  //color: #fff;
}
.price-per-p,
.sub-total-p,
.discount-p,
.total-p,
.tax-p {
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  border-bottom: #484848 1px solid;
  border-left: #484848 1px solid;
  padding: 5px;
  /* background-color: #28c76f; */
  //color: #fff;
  border-radius: 0 10px 10px 0;
}
.price-card {
  /* make cursor pointer */
  transition: all 0.2s ease-in-out;
}
.price-card:hover {
  /* make cursor pointer */

  transform: scale(1.03);
  cursor: pointer;
}
/* .primary-background {
  background-color: #de39a5 !important;
} */
.v-slider__thumb {
  height: 20px !important;
  width: 20px !important;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px;
}
.table-row {
  display: flex;
  justify-content: end;
  align-items: center;
  /* padding: 0.5rem 0; */
  /* border-bottom: 1px solid #e9ecef; */
}
.text-class {
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  border-bottom: #e9ecef 1px solid;
  padding: 5px;
  /* background-color: #17a2b8; */
  // color: #fff;
}
.price-class {
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  border-bottom: #e9ecef 1px solid;
  border-left: #e9ecef 1px solid;
  padding: 5px;
  /* background-color: #28c76f; */
  // color: #fff;
  // border-radius: 0 10px 10px 0;
}
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
</style>

<style scoped>
.pricing-free-trial {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
