<template>
  <div v-if="render">
    <b-row class="match-height">
      <b-col md="5">
        <b-card>
          <div class="address-list">
            <div v-for="(item, index) in userAddresses" :key="index" class="">
              <div class="d-flex justify-content-between align-items-center">
                <b-form-radio v-model="selectedAdressIndex" class="text-white" name="selectedAddress" :value="index">
                </b-form-radio>
                <div class="flex flex-column bg-primary mt-1 p-2 rounded text-white w-100">
                  <div class="address-title font-weight-bolder">
                    {{ item.label }}
                  </div>
                  <div class="address-content">
                    {{ item.address }}
                  </div>
                </div>
                <b-button size="sm" class="m-1" variant="info" @click="editAddress(index)">
                  <feather-icon icon="EditIcon" size="24"></feather-icon>
                </b-button>
                <b-button size="sm" class="m-1" variant="danger" @click="deleteAddress(index)">
                  <feather-icon icon="XCircleIcon" size="24"></feather-icon>
                </b-button>
              </div>
            </div>
          </div>
          <div class="legal-content d-flex justify-content-center mt-2">
            <b-button variant="success" v-b-modal.address-modal>
              <feather-icon icon="PlusIcon" size="18" class="mr-1"></feather-icon>{{ $t("New Address") }}
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col md="7">
        <b-card>
          <div class="d-flex flex-column justify-content-between h-100">
            <!-- Invoice Description: Table -->
            <div class="package-info text-center">
              <h3>{{ $t('Chosen Package Info') }}</h3>
              <p class="text-left">
              <h3>{{ total.pack.title }}</h3>
              <ul>
                <li v-for="(item, index) in total.pack.planBenefits" :key="index" class="text-left">
                  {{ item }}
                </li>
              </ul>
              </p>
            </div>
            <div class="invoice-description h-100">
              <!-- <b-table stacked :items="total"></b-table> -->
              <!-- make input for coupon -->
              <div class="price-table d-flex justify-content-between h-100 m-1 w-100">
                <div class="coupon w-100 m-1">
                  <validation-observer ref="validateCoupon">
                    <validation-provider #default="{ errors }" name="Coupon" rules="required">
                      <b-form-group label-for="coupon" :label="$t('Coupon')">
                        <b-form-input v-model="coupon" id="coupon" :placeholder="$t('Coupon')" ref="couponInput" />
                      </b-form-group>
                    </validation-provider>
                    <b-button variant="primary" class="d-flex align-items-center" type="submit"
                      :disabled="disableButton == true" @click.prevent="postCoupon">
                      <feather-icon icon="CheckIcon" size="18" class="mr-1"></feather-icon>{{ $t("Apply Coupon") }}
                    </b-button>
                  </validation-observer>
                  <b-row>
                    <div v-for="(item, index) in couponList" :key="index" class="mr-1 mt-1">
                      <b-badge variant="info">
                        {{ index + 1 }} - {{ item }}
                      </b-badge>
                    </div>
                  </b-row>
                  <!-- <b-form-group label-for="coupon" :label="$t('Coupon')">
                    <b-form-input v-model="coupon" id="coupon" :placeholder="$t('Coupon')" />
                  </b-form-group>
                  <b-button variant="primary" class="d-flex align-items-center" type="submit"
                    :disabled="disableButton == true" @click.prevent="postCoupon">
                    <feather-icon icon="CheckIcon" size="18" class="mr-1"></feather-icon>{{ $t("Apply Coupon") }}
                  </b-button> -->
                </div>
                <div class="ali d-flex flex-column justify-content-between w-100 m-1">
                  <div class="table-row">
                    <div class="sub-total">{{ $t("Sub Total") }}</div>
                    <div class="sub-total-p">{{ currencyParser(total.subTotal) }}</div>
                  </div>
                  <div class="table-row">
                    <div class="price-per-sub">{{ $t("Per Client") }}</div>
                    <div class="price-per-p">{{ currencyParser(total.subTotal / total.amount) }} (+{{ $t('VAT') }})</div>
                  </div>
                  <div class="table-row">
                    <div class="discount">{{ $t("Discount") }}</div>
                    <div class="discount-p">
                      {{ currencyParser(total.price - total.paidPrice) }} - %{{ total.totaldiscountRate.toFixed(2) }}
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="tax">{{ $t("Tax") }}</div>
                    <div class="tax-p">{{ currencyParser(total.tax) }} </div>
                  </div>
                  <div class="table-row">
                    <div class="total">{{ $t("Total") }}</div>
                    <div class="total-p">{{ currencyParser(total.paidPrice) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="proceed-payment m-2">
              <validation-observer ref="check">
                <b-col md="12">
                  <validation-provider #default="{ errors }" :name="$t('check')" rules="required">
                    <b-form-group :label="$t('Terms and Conditions')" label-for="terms">
                      <b-form-checkbox v-model="termCheck" name="terms">
                        <b-link @click="preinformtextF"> Ön Bilgilendirme Metni ve Hizmet Sözleşmesi </b-link> 'ne dair
                        {{ $t("I agree to the terms and conditions") }}
                      </b-form-checkbox>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </validation-observer>
              <b-button variant="primary" :disabled="!termCheck" @click.prevent="goPayment"
                class="btn-block">{{ $t("Proceed to Payment") }}</b-button>
            </div>
            <div>
              <b-modal size="lg" id="IframeModal" @show="" hide-footer>
                <div style="height: 90vh !important">
                  <iframe :src="userAddress" :allowpaymentrequest="true" title="Payment" width="100%" height="100%"
                    frameborder="0"></iframe>
                </div>
              </b-modal>
              <!-- modal for address -->
              <div>
                <b-modal size="lg" id="address-modal" centered ref="addressModal" hide-footer>
                  <b-row>
                    <b-col>
                      <validation-observer ref="simpleRules">
                        <b-form-group>
                          <label for="Label">Adres Etiketi</label>
                          <validation-provider #default="{ errors }" name="Adres Etiketi" rules="required">
                            <b-form-input v-model="addressLabel" :state="errors.length > 0 ? false : null"
                              placeholder="Etiket" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <label for="City">İl</label>
                          <validation-provider #default="{ errors }" name="Şehir" rules="required">
                            <b-form-select id="city" v-model="selectedCity" class="" name="city">
                              <b-form-select-option v-for="city in cities" :key="city.plate" :value="city.city">
                                {{ city.city }}
                              </b-form-select-option>
                            </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <label for="City">İlçe</label>
                          <validation-provider #default="{ errors }" name="İlçe">
                            <b-form-select id="city" v-model="selectedDistrict" class="" name="selectedDistrict">
                              <b-form-select-option v-for="dist in district" :key="selectedCity">
                                {{ dist }}
                              </b-form-select-option>
                            </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <label for="Address">Adres</label>
                          <validation-provider #default="{ errors }" name="Adres" rules="required">
                            <b-form-input v-model="address" :state="errors.length > 0 ? false : null"
                              placeholder="Adres" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <label for="zipcode">Posta Kodu</label>
                          <validation-provider #default="{ errors }" name="Posta Kodu" rules="required|digits:5">
                            <b-form-input v-model="zipcode" :state="errors.length > 0 ? false : null"
                              placeholder="Posta Kodu" type="number" max="5" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <validation-provider #default="{ errors }" name="TCKN veya VKN" rules="required">
                            <b-form-radio-group name="" class="d-flex align-content-center justify-content-around">
                              <b-form-radio v-model="vknOrTckn" value="tckn">Bireysel Fatura</b-form-radio>
                              <b-form-radio v-model="vknOrTckn" value="vkn">Kurumsal Fatura</b-form-radio>
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <label
                            for="identificationNumber">{{vknOrTckn == 'vkn' ? 'Vergi Numarası' : 'TC Kimlik No'}}</label>
                          <validation-provider #default="{ errors }" name="TCKN veya Vergi Numarası"
                            rules="required|min:10|max:11">
                            <b-form-input v-model="identificationNumber" :state="errors.length > 0 ? false : null"
                              placeholder="TCKN / Vergi No" type="number" max="11" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group v-if="vknOrTckn == 'vkn' ? true : false">
                          <label for="taxDept">Vergi Dairesi</label>
                          <validation-provider #default="{ errors }" name="Vergi Dairesi"
                            :rules="vknOrTckn == 'vkn' ? 'required' : ''">
                            <b-form-input v-model="taxDept" :state="errors.length > 0 ? false : null"
                              placeholder="Vergi Dairesi" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-button variant="primary" type="submit" @click.prevent="validationForm">
                          {{ $t("Save") }}
                        </b-button>
                      </validation-observer>
                    </b-col>
                  </b-row>
                </b-modal>
              </div>
              <!-- modal for terms -->
              <div>
                <b-modal size="lg" centered ref="preinformtextF" hide-footer>
                  <b-card>
                    <preinformtext :informations="informations" />
                  </b-card>
                </b-modal>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* import feather icon */
import preinformtext from "./preinformtext.vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ValidationProvider, ValidationObserver, localize,extend } from "vee-validate";
import tr from "vee-validate/dist/locale/tr.json";
// import {t as translate} from "@/libs/i18n";
// import VVLocale from `vee-validate/dist/locale/${i18n.locale.split('-')[0]}.json`;
import {
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BButton,
  BModal,
  BFormRadio,
  BIconArrowRightShort,
  BFormCheckbox,
  BCard,
  BBadge,
  VBTooltip,
  VBModal,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
const cities = require("./turkey.js");
import Ripple from "vue-ripple-directive";
import axios from "@axios";
// import tr from "vee-validate/dist/locale/tr.json"

// localize({
//   tr
// });
// localize("tr");

const {
  axiosRoutes: { payment: paymentPath, address: addressPath, user: userPath },
} = require("/G_CONFIG");

export default {
  props: {
    total: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  components: {
    preinformtext,
    BBadge,
    BCard,
    BFormCheckbox,
    ValidationObserver,
    BFormInvalidFeedback,
    ValidationProvider,
    BFormRadio,
    FeatherIcon,
    BModal,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BLink,
  },
  data() {
    return {
      render: true,
      addressSaved: false,
      index: null,
      userInfo: null,
      selectedAdressIndex: 0,
      selectedAddress: {
        label: "",
        city: "",
        country: "",
        address: "",
        zipcode: "",
        identificationNumber: "",
        taxDept: "",
      },
      userAddress: "",
      userAddresses: [],
      /* selected: null, */
      cities: cities,
      selectedCity: "",
      district: [],
      selectedDistrict: "",
      formIndex: 0,
      firstName: "",
      companyName: "",
      Email: "",
      Phone: "",
      taxNumber: "",
      taxDepartment: "",
      address: "",
      zipcode: "",
      addressLabel: "",
      termCheck: false,
      identificationNumber: "",
      taxDept: "",
      _id: "",
      informations:{
        name: "",
        address: "",
        phone: "",
        email: "",
        taxNumber: "",
        taxDepartment: "",
      },
      // isFilled: false,
      required,
      couponList: [],
      coupon: "",
      vknOrTckn: "tckn",
      currencyParser: new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format,
    };
  },
  async created() {
    extend('max',{
      validate(value, {length}) {
        return value.length <= length;
      },
      params: ['length'],
      message: '{_field_} {length} karakteri geçemez.'
    });
    localize("tr");
    this.cities = cities;
    let postData = {
      amount: this.total.amount,
      coupons: [],
      getPricing: true,
    };
    axios.post(paymentPath.new, postData).then((res) => {
      this.total = res.data;
      console.log(this.total);
    });
    axios.get(userPath.my).then((res) => {
      this.userInfo = res.data;
    });
    this.getAddresses();
  },
  mounted() {
    console.log("mounted");
    console.log(this.selectedAdressIndex);

  },

  methods: {
    preinformtextF() {
      this.informations.name = this.userInfo.name;
      this.informations.address = this.userAddresses[this.selectedAdressIndex].address;
      this.informations.phone = this.userInfo.phone;
      this.informations.email = this.userInfo.email;
      this.informations.taxNumber = this.userAddresses[this.selectedAdressIndex].identificationNumber;
      this.informations.taxDepartment = this.userAddresses[this.selectedAdressIndex].taxDept;
      this.$refs["preinformtextF"].show();
    },
    getAddresses(deleted=false) {
      axios.get(addressPath.getAll).then((res) => {
        this.userAddresses = res.data;
        if(deleted)
        this.selectedAdressIndex = this.userAddresses.length >0? this.userAddresses.length-1 :0;
      });
    },
    editAddress(index) {
      this.selectedAdressIndex = index;
      this.addressLabel = this.userAddresses[index].label;
      this.selectedCity = this.userAddresses[index].city;
      this.address = this.userAddresses[index].address;
      this.zipcode = this.userAddresses[index].zipcode;
      this.identificationNumber = this.userAddresses[index].identificationNumber;
      this.taxDept = this.userAddresses[index].taxDept;
      this._id = this.userAddresses[index]._id;
      this.$refs['addressModal'].show()

    },
    deleteAddress(index) {
      this.$swal({
        title: this.$t("Are you sure to delete this address?"),
        text: this.$t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, delete it!"),
        cancelButtonText: this.$t("No, cancel!"),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(addressPath.delete + this.userAddresses[index]._id).then((res) => {
            this.getAddresses(true);
            // this.selectedAdressIndex = this.userAddresses.length;
            this.addressLabel = "";
            this.selectedCity = "";
            this.address = "";
            this.zipcode = "";
            this.identificationNumber = "";
            this.taxDept = "";
          });
          this.$swal({
            icon: 'success',
            title: this.$t("Deleted!"),
            text: this.$t("Your address has been deleted."),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    disableButton() {
      if (this.addressLabel !== "") {
        return true;
      }
      console.log(disableButton);
    },
    postCoupon() {
      if (this.coupon === "" || this.coupon === null || this.coupon === undefined) {
        this.$swal({
          title: this.$t("Please enter a coupon"),
          icon: "error",
          confirmButtonText: this.$t("Ok"),
          timer: 3000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      else {
        if (this.couponList.includes(this.coupon)) {
          this.$swal({
            title: this.$t("Coupon is already applied"),
            icon: "error",
            confirmButtonText: this.$t("Ok"),
            timer: 3000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return;
        } else {
          let postData = {
            amount: this.total.amount,
            coupons: [...this.couponList,this.coupon],
            getPricing: true,
          };
          axios.post(paymentPath.new, postData).then((res) => {
            // console.log(res.data);
            this.total = res.data;
            // console.log(this.total);
            let couponMap = this.total.coupons.map((item) => item.code);
            if(!couponMap.includes(this.coupon))
              throw new Error("Coupon is not valid or expired");
            this.couponList= couponMap;
            //this.couponList.push(this.coupon);
            this.coupon = "";
            this.$refs.couponInput.value = '';
            this.$swal({
              title: this.$t("Coupon Applied Successfully"),
              icon: "success",
              confirmButtonText: this.$t("Ok"),
              timer: 3000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }).catch((err) => {
            this.coupon = "";
            this.$refs.couponInput.value = '';
            this.$swal({
              title: this.$t("Coupon is not valid or expired"),
              icon: "error",
              confirmButtonText: this.$t("Ok"),
              timer: 3000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      }
    },
    postAddress() {
      let newAddressPattern = {
        label: this.addressLabel,
        city: this.selectedCity,
        country: "Türkiye",
        address: this.address,
        zipcode: this.zipcode,
        identificationNumber: this.identificationNumber,
        taxDept: this.taxDept,
        _id: this._id,
      };
      console.log("id: " + newAddressPattern._id);
      if (newAddressPattern._id !== "" && newAddressPattern._id !== null && newAddressPattern._id !== undefined) {
        console.log(addressPath.update + newAddressPattern._id)

        axios.put(addressPath.update + newAddressPattern._id, newAddressPattern).then((res) => {
          this.getAddresses();
          this.selectedAdressIndex = this.userAddresses.length;
          this.addressLabel = "";
          this.selectedCity = "";
          this.address = "";
          this.zipcode = "";
          this.identificationNumber = "";
          this.taxDept = "";

          this.$swal({
            title: this.$t("Changes Saved Successsfully"),
            icon: "success",
            confirmButtonText: this.$t("Ok"),
            timer: 3000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      } else {
        delete newAddressPattern._id;
        axios
          .post(addressPath.new, newAddressPattern)
          .then((res) => {
            this.getAddresses();
            this.selectedAdressIndex = this.userAddresses.length;
            this.addressLabel = "";
            this.selectedCity = "";
            this.address = "";
            this.zipcode = "";
            this.identificationNumber = "";
            this.taxDept = "";

            this.$swal({
              title: this.$t("Changes Saved Successsfully"),
              icon: "success",
              confirmButtonText: this.$t("Ok"),
              timer: 3000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    goPayment() {
      if (this.userAddresses.length < 1) {
        this.$swal({
          title: this.$t("Please add an address"),
          icon: "error",
          confirmButtonText: this.$t("Ok"),
          timer: 3000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      else {
        // eslint-disable-next-line
        let payment = {
          amount: this.total.amount,
          coupons: this.couponList,
          addressLabel: this.userAddresses[this.selectedAdressIndex].label,
        };
        console.log(this.total.amount);

        axios.post(paymentPath.new, payment).then((res) => {
          this.userAddress = res.data;
          console.log(this.userAddresses[this.selectedAdressIndex].label);
        });
        this.$bvModal.show("IframeModal");
      }
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    saveAddress() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Address Saved",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.postAddress();
          this.$refs['addressModal'].hide()

        }
      })

    },
  },
  watch: {
    selectedCity() {
      this.district = this.cities.find(
        (city) => city.city === this.selectedCity
      ).district;
    },
    selectedAdressIndex() {
      this.getAddress();

    },
    vknOrTckn(){
      console.log(this.vknOrTckn);
    }
  },
};
</script>
<!-- <style scoped lang="scss">
  @import "@core/scss/vue/libs/vue-wizard.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
</style> -->
<style scoped lang="scss">
.address-list {
  overflow-y: auto;
}

small {
  font-size: 12px;
  color: #ea5455;
}

.table-row {
  display: flex;
  justify-content: end;
  align-items: center;
  /* padding: 0.5rem 0; */
  /* border-bottom: 1px solid #e9ecef; */
}

.price-per-sub,
.sub-total,
.discount,
.total,
.tax {
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  border-bottom: #e9ecef 1px solid;
  padding: 5px;
  /* background-color: #17a2b8; */
  color: #fff;
}

.price-per-p,
.sub-total-p,
.discount-p,
.total-p,
.tax-p {
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  border-bottom: #e9ecef 1px solid;
  border-left: #e9ecef 1px solid;
  padding: 5px;
  /* background-color: #28c76f; */
  color: #fff;
  border-radius: 0 10px 10px 0;
}
</style>
